var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"users-list"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteConfirm),callback:function ($$v) {_vm.deleteConfirm=$$v},expression:"deleteConfirm"}},[_c('v-card',[_c('v-card-title',{class:("headline grey " + (_vm.$vuetify.theme.dark ? 'darken' : 'lighten') + "-2"),attrs:{"primary-title":""}},[_vm._v("Delete "+_vm._s(_vm.userToDelete))]),_c('v-card-text',{staticClass:"mt-3"},[_vm._v("Are you sure you want to delete this user?")]),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteUser(_vm.userToDelete);}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteConfirm = false}}},[_vm._v("No")])],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[(!_vm.editMode)?_c('v-btn',{staticClass:"primary mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.edit()}}},[_vm._v("Edit")]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"primary mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"primary mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]):_vm._e(),_c('div',{staticClass:"search-text"},[_c('v-text-field',{attrs:{"append-icon":"search","type":"text","label":"Search"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-style",attrs:{"items":_vm.users,"headers":_vm.headers,"loading":_vm.isLoading,"search":_vm.searchText,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("arrayList")(item.roles))+" "),(_vm.editMode)?_c('div',[_c('v-select',{attrs:{"items":_vm.roles,"label":"Add Roles","multiple":"","small-chips":""},on:{"change":function($event){return _vm.addRole(item)}},model:{value:(item.newRoles),callback:function ($$v) {_vm.$set(item, "newRoles", $$v)},expression:"item.newRoles"}})],1):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Active' : 'Disabled')+" ")]}},{key:"item.lenders",fn:function(ref){
var item = ref.item;
return [_vm._l((item.lenders),function(lender,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(lender.name)+" ("+_vm._s(lender.lenderNumber)+") ")])}),(_vm.editMode)?_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.lenders,"small-chips":"","item-text":"name","item-value":"lenderId","hide-details":"","label":"Select Lender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Select Lender ")])]},proxy:true},{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":function($event){_vm.add(item, data); data.select},"click:close":function($event){return _vm.remove(item, data)}}},'v-chip',data.attrs,false),[_vm._v(_vm._s(data.item.name)+" ("+_vm._s(data.item.id)+")")])]}},{key:"item",fn:function(data){return [_vm._v(_vm._s(data.item.name)+" ("+_vm._s(data.item.id)+")")]}}],null,true),model:{value:(item.lenderId),callback:function ($$v) {_vm.$set(item, "lenderId", $$v)},expression:"item.lenderId"}})],1):_vm._e()]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commonDateWithTime")(item.lastLogin))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary mr-2",attrs:{"primary":"","small":""},on:{"click":function($event){return _vm.logoutUser(item.id)}}},[_vm._v("Logout")]),_c('v-btn',{staticClass:"mr-2",class:item.active ? 'error' : 'success',attrs:{"primary":"","small":""},on:{"click":function($event){return _vm.toggleUserStatus(item)}}},[_vm._v(_vm._s(item.active ? 'Deactivate' : 'Activate'))]),(!item.active)?_c('v-btn',{attrs:{"primary":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v("Delete")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" No users were found. ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }