var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"queries-list"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.querySearch),callback:function ($$v) {_vm.querySearch=$$v},expression:"querySearch"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-style",attrs:{"items":_vm.queries,"headers":_vm.headers,"loading":_vm.isLoading,"search":_vm.querySearch},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(tag))])})}},{key:"item.viewable",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateQuery('viewable', $event, item)}},model:{value:(item.viewable),callback:function ($$v) {_vm.$set(item, "viewable", $$v)},expression:"item.viewable"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text minus-chip ma-0",attrs:{"color":"success"},on:{"click":function($event){return _vm.openQuery(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"play"}})],1),_c('v-dialog',{attrs:{"width":'75%'},on:{"input":function($event){return _vm.startClone(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"white--text minus-chip ml-2 mr-0 my-0",attrs:{"color":"primary"}},on),[_c('font-awesome-icon',{attrs:{"icon":"clone"}})],1)]}}],null,true),model:{value:(item.cloneDialog),callback:function ($$v) {_vm.$set(item, "cloneDialog", $$v)},expression:"item.cloneDialog"}},[(_vm.clonedQuery)?_c('v-card',[_c('v-card-title',{class:("headline grey " + (_vm.$vuetify.theme.dark ? 'darken' : 'lighten') + "-2"),attrs:{"primary-title":""}},[_vm._v("Clone "+_vm._s(item.name))]),_c('v-card-text',[_c('v-text-field',{staticClass:"mt-3",attrs:{"label":"Name"},model:{value:(_vm.clonedQuery.name),callback:function ($$v) {_vm.$set(_vm.clonedQuery, "name", $$v)},expression:"clonedQuery.name"}}),_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.clonedQuery.description),callback:function ($$v) {_vm.$set(_vm.clonedQuery, "description", $$v)},expression:"clonedQuery.description"}}),_c('QueryDefinition',{attrs:{"selections":_vm.selections},model:{value:(_vm.clonedQuery.query),callback:function ($$v) {_vm.$set(_vm.clonedQuery, "query", $$v)},expression:"clonedQuery.query"}})],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){item.cloneDialog = false; _vm.cloneQuery();}}},[_vm._v("Save")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){item.cloneDialog = false}}},[_vm._v("Cancel")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"white--text minus-chip ml-2 mr-0 my-0",attrs:{"color":"error"}},on),[_c('font-awesome-icon',{attrs:{"icon":"minus"}})],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',{class:("headline grey " + (_vm.$vuetify.theme.dark ? 'darken' : 'lighten') + "-2"),attrs:{"primary-title":""}},[_vm._v("Delete "+_vm._s(item.name))]),_c('v-card-text',[_vm._v("Are you sure you want to delete this query?")]),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){item.deleteDialog = false; _vm.deleteQuery(item);}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v("No")])],1)],1)],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" No queries were found. ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }