











import {
  Component, Prop, Emit, Vue, Mixins, Model, Watch,
} from 'vue-property-decorator';

import { latLngBounds } from 'leaflet';

import _, { find } from 'lodash';

import statesJson from '@/data/usa-states.json';
import statesData from '@/data/example-data.json';

function parseProperty(data: any, property: string): any {
  const nestedProperties = property.split('.');

  return nestedProperties.reduce((finalProperty: { [key: string]: any }, theProperty) => {
    if (!finalProperty) {
      return data[theProperty];
    }

    return finalProperty[theProperty];
  }, undefined);
}

@Component({
  name: 'count-table',
})
export default class CountTable extends Vue {
  @Prop({
    type: Array,
    default: (): any[] => [],
  }) private readonly data!: any[];

  @Prop({
    type: String,
    default: 'index',
  }) private readonly property!: string;

  @Prop({
    type: String,
    default: 'Property',
  }) private readonly label!: string;

  // Computed
  get tableData(): any[] {
    return this.data.reduce((resultSet, dataEntry) => {
      if (!parseProperty(dataEntry, this.property)) return resultSet;

      const resultEntry = find(
        resultSet, (theResultEntry) => theResultEntry.property === parseProperty(dataEntry, this.property),
      );

      if (resultEntry) {
        resultEntry.value += 1;
      } else {
        resultSet.push({
          property: parseProperty(dataEntry, this.property),
          value: parseInt(dataEntry.count, 10) || 1,
        });
      }

      return resultSet;
    }, []);
  }

  get headers(): any[] {
    return [
      {
        text: this.label, value: 'property', width: '50%', sortable: false,
      },
      {
        text: 'Parcel Count', value: 'value', width: '50%', sortable: false,
      },
    ];
  }
}
