import { Role } from '@/entities/User';
import RoleApiService from '@/services/api/RoleApiService';

class RoleService {
  async getRoles(): Promise<Role[]> {
    const response = await RoleApiService.getRoles();

    return response.map((serviceRole) => new Role(serviceRole));
  }
}

export { RoleService as default };
