import Axios from 'axios';

import { IServiceRole } from '@/services/api/models/IServiceUser';

export default {
  async getRoles(): Promise<IServiceRole[]> {
    const response = await Axios.get<IServiceRole[]>('/roles');

    return response.data;
  },
};
