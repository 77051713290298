
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  Component,
  Prop,
  Emit,
  Mixins,
  Watch,
} from 'vue-property-decorator';

import { debounce, cloneDeep, at } from 'lodash';

import {
  required, minLength, email, integer,
} from 'vuelidate/lib/validators';
import { validationMixin, Validation } from 'vuelidate';
import axios from 'axios';

import Lender from '@/entities/Lender';
import Verified from '@/entities/Verified';
import IFile from '@/entities/IFile';
import StatusTrackable from '@/entities/StatusTrackable';

import VerifiedCheckbox from '@/components/VerifiedCheckbox.vue';
import UsaChloropleth from '@/components/maps/UsaChloropleth.vue';
import CountTable from '@/components/CountTable.vue';
import EntityHistory from '@/views/history/EntityHistory.vue';

import LenderService from '@/services/lenders';
import ContactTypesService from '@/services/contactTypes';

import {
  phoneNumber, usZipCode, permissiveUrl, stateTerritoryAbbr, fullDate,
} from '@/validations';
import { fileSizeValidator } from '@/validations/vuetify';
import ValidationErrors from '@/mixins/ValidationErrors.vue';
import PreventDirtyLeave from '@/mixins/PreventDirtyLeave.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';
import CurrencyFormatter from '@/mixins/CurrencyFormatter.vue';
import GlobalNotifications from '@/mixins/GlobalNotifications.vue';

import vuelidateToPatch, {
  JsonPatchPayload, verifiedAddressConverter, JsonPatchEntry, currencyFieldConverter, JsonPatchOperator,
} from '@/helpers/vuelidateToPatch';
import { IServiceRelatedLender } from '@/services/api/models/IServiceLender';
import { DateTime } from 'luxon';
import LoanService from '@/services/loans';

const validations: any = {
  lender: {
    id: {},
    name: { required, minLength: minLength(1) },
    servicingType: {},
    address: {
      value: {
        address1: {},
        address2: {},
        city: {},
        state: { stateTerritoryAbbr },
        zipCode: { usZipCode },
      },
      verified: {},
      verifiedBy: {},
      verifiedOn: {},
    },
    email: { email },
    phoneNumber: { phoneNumber },
    parcelState: {},
    notes: {},
    website: { permissiveUrl },
    paperlessReporting: {},
    paperlessReportingFormat: {},
    paperlessReportingNotes: {},
    heldBill: {},
    heldBillNotes: {},
    delinquentSearchUpdate: {},
    delinquentSearchNotes: {},
    updateNotes: {},
    lenderReportingNotes: {},
    itSystem: {},
    preferredFileType: {},
    escrowReportingFile: {},
    reportVerificationFiles: {},
    crossReferenceCoding: {},
    billed: { integer, minValue: 1 },
    forEvery: { integer, minValue: 1 },
    serviceFee: {},
    specialBillingNotes: {},
    paperlessBilling: {},
    paperlessBillingFormat: {},
    paperlessBillingNotes: {},
    howLoansAreReceived: {},
    loansAddedFrequency: {},
    loansRemovedFrequency: {},
    loansLastAdded: { fullDate },
    establishedDate: { fullDate },
    loanNotes: {},
    active: {},

    lenderVerified: {},

    // Collections
    relatedLenders: {
      $each: {},
    },

    contacts: {
      $each: {
        $new: {},
        type: {},
        name: {},
        phone: { phoneNumber },
        extension: {},
        fax: { phoneNumber },
        email: { email },
        address: {},
        notes: {},
        hours: {},
      },
    },
  },
};

type TrackableRelatedLender = StatusTrackable & IServiceRelatedLender;

@Component({
  name: 'lenders-detail',
  validations,
  components: {
    VerifiedCheckbox,
    UsaChloropleth,
    CountTable,
    EntityHistory,
  },
  mixins: [validationMixin],
})
export default class LendersDetail extends Mixins(UserPermissions, GlobalNotifications, PreventDirtyLeave, ValidationErrors, CurrencyFormatter) {
  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: any;

  @Prop({
    type: String,
  }) private readonly id!: string;

  @Prop({
    type: String,
  }) private readonly view!: string;

  private profileIdx: number = 0;

  private patchError: any = null;
  private editMode: boolean = false;
  private loading: boolean = false;
  private updating: boolean = false;
  private contactSearch: string = '';
  private contactTypes: any[] = []; // JSON.parse(localStorage.contactTypes);
  private parcelMapData: any[] = [];
  private parcelView: string = 'Table';
  private currentDebounce: Function = null;
  private service: LenderService = new LenderService();
  private contactTypesService: ContactTypesService = new ContactTypesService();

  private files: File[] = [];
  private fileRules: Function[] = [
    fileSizeValidator(15000000, 'File size limit is 15 MB'),
  ];
  private fileValid: boolean = false;

  // Lender pic
  private hasAvatar: boolean = true;
  private showPictureUploadDialog: boolean = false;
  private pictureFile: File = null;
  private rawPicture: string = null;
  private croppedPicture: File = null;
  private aspectRatio: number = 1.0;

  // List controls
  private addingNewRelatedLender: boolean = false;
  private addingNewContact: boolean = false;

  // Related lenders
  private searchTerm: string = '';
  private selectedLender: Lender & StatusTrackable & { state: string, county: string } = null;
  private newLenders: (Lender & StatusTrackable & { state: string, county: string })[] = [];
  private removedLenders: TrackableRelatedLender[] = [];
  private otherLenders: Lender[] = [];

  // Contacts
  private newContact: any = null;
  private newContacts: any[] = [];
  private removedContacts: any[] = [];

  private noMask: any = {
    mask: '*'.repeat(255),
    tokens: {
      '*': { pattern: /./ },
    },
  };

  private lender: Lender = null;

  private url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;
  private confirmingDeletion = false;
  private showingHistory = false;

  private confirmDeactivation = false;

  private contactHeaders: any[] = [
    {
      text: 'Type',
      value: 'type',
      sortable: true,
      width: '1%',
      type: 'chip',
    },
    {
      text: 'Name',
      value: 'name',
      sortable: false,
      width: '1%',
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
      width: '1%',
      type: 'phone',
    },
    {
      text: 'Extension',
      value: 'extension',
      sortable: false,
      width: '1%',
      mask: 'x#######',
    },
    {
      text: 'Fax',
      value: 'fax',
      sortable: false,
      width: '1%',
      type: 'phone',
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
      width: '1%',
      type: 'email',
    },
    {
      text: 'Address',
      value: 'address',
      sortable: false,
      width: '1%',
      type: 'address',
    },
    {
      text: 'Notes',
      value: 'notes',
      sortable: false,
      width: '1%',
    },
    {
      text: 'Hours',
      value: 'hours',
      sortable: false,
      width: '1%',
    },
    {
      text: 'Actions',
      value: 'controls',
      sortable: false,
      width: '1%',
      type: 'action',
    },
  ];

  private parcelCountyHeaders: any[] = [
    {
      text: 'State',
      value: 'state',
      sortable: true,
      width: '1%',
    },
    {
      text: 'County',
      value: 'county',
      sortable: true,
      width: '1%',
    },
    {
      text: 'Count',
      value: 'count',
      sortable: true,
      width: '1%',
    },
  ];

  private agencyBreakDownHeaders: any[] = [
    {
      text: 'Agency Number',
      value: 'capAgency',
      sortable: true,
      width: '1%',
    },
    {
      text: 'Name',
      value: 'name',
      sortable: true,
      width: '1%',
    },
    // {
    //   text: 'County',
    //   value: 'county',
    //   sortable: true,
    //   width: '1%',
    // },
    // {
    //   text: 'State',
    //   value: 'state',
    //   sortable: true,
    //   width: '1%',
    // },
    {
      text: 'Count',
      value: 'count',
      sortable: true,
      width: '1%',
    },
  ];

  private paperlessReportingFormatItems = [
    {
      text: 'Mail',
      value: 'MAIL',
    },
    {
      text: 'Email',
      value: 'EMAIL',
    },
    {
      text: 'Cloud',
      value: 'CLOUD',
    },
  ];

  private paperlessBillingFormatItems = [
    {
      text: 'CD',
      value: 'CD',
    },
    {
      text: 'Email',
      value: 'EMAIL',
    },
    {
      text: 'Cloud',
      value: 'CLOUD',
    },
  ];

  private howLoansAreReceivedItems = [
    {
      text: 'Full file portfolio',
      value: 'FULL FILE PORTFOLIO',
    },
    {
      text: 'Partial file new loans only',
      value: 'PARTIAL FILE NEW LOANS ONLY',
    },
    {
      text: 'Paper report new loans',
      value: 'PAPER REPORT NEW LOANS',
    },
  ];

  private loansAddedRemovedFrequencyItems = [
    {
      text: 'Monthly',
      value: 'MONTHLY',
    },
    {
      text: 'Quarterly',
      value: 'QUARTERLY',
    },
    {
      text: 'Not regularly',
      value: 'NOT REGULARLY',
    },
  ];

  // Watchers
  @Watch('lenderValidation', { deep: true })
  onLenderValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      // Only deverify the entity if a non-verified field was touched
      const verifyIsDirtied = this.$v.lender.lenderVerified.$dirty;
      if (!verifyIsDirtied) {
        this.verifyEntity(false);
      }
    }
  }

  @Watch('addressValidation', { deep: true })
  onAddressValidationChanged(validation: Validation) {
    if (validation.$anyDirty) {
      this.verifyField(this.lender.address, false, 'address');
    }
  }

  @Watch('showPictureUploadDialog')
  async onDialogVisibilityUpdated(dialogShown: boolean) {
    if (!dialogShown) {
      this.pictureFile = null;
      this.rawPicture = null;
      this.croppedPicture = null;
    }
  }

  // Computed
  get canEdit(): boolean {
    if (this.isUser) {
      return true;
    }

    if (this.isLenderAdmin && this.user.lenderId.toLowerCase() === this.lender.lenderId.toLowerCase()) {
      return true;
    }

    return false;
  }

  get dirtyFields(): string[] {
    return Object.keys(this.$v.lender.$params)
      .filter((fieldName) => this.$v.lender[fieldName].$anyDirty);
  }

  get invalidFields(): string[] {
    return Object.keys(this.$v.lender.$params)
      .filter((fieldName) => this.$v.lender[fieldName].$invalid);
  }

  get nestedInvalidFields(): string [] {
    const leafParams = this.$v.lender.$flattenParams();
    const invalidFields: string[] = [];

    leafParams.forEach((param) => {
      const path = param.path.join('.');
      if (at(this.$v.lender, path)[0].$invalid) {
        invalidFields.push(path);
      }
    });

    return invalidFields;
  }

  get options() {
    return {
      locale: 'en',
      currency: 'USD',
      precision: 2,
    };
  }

  get lenderValidation(): any {
    return this.$v.lender;
  }

  get addressValidation(): any {
    return this.$v.lender.address.value;
  }

  // Hooks
  async created() {
    this.editMode = this.$route.query.editMode === 'true';
    await this.getLender();

    const contactTypeResponse = await this.contactTypesService.getContactTypes({ search_entity: 'lenders' });
    this.contactTypes = contactTypeResponse.types.map((type) => type.name);

    this.files = [];
  }

  getLender(quiet: boolean = false, field: keyof Lender = null) {
    try {
      this.loading = !quiet;
      this.service.getLender(this.id || this.$route.params.id)
        .then((lender) => {
          if (field) {
            (this.lender as any)[field] = lender[field];
            return;
          }

          this.lender = lender;

          this.lender.relatedLenders.forEach((c, i) => {
            c.index = i;
          });

          this.parcelMapData = this.lender.parcelState.map((value) => ({
            name: value.state,
            name_abbr: value.state,
            value: value.count,
          }));

          this.loading = false;
          this.$nextTick(() => {
            this.$v.$reset();
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getLenders(searchTerm: string) {
    this.searchTerm = searchTerm;
    if (!this.currentDebounce) {
      this.currentDebounce = debounce(this.searchForLender, 1000);
    }

    this.currentDebounce();
  }

  getLenderPicture(lender: Lender) {
    return `${this.$baseUrl}/lenders/${lender.lenderId}/files/profile?idx=${this.profileIdx}`;
  }

  async searchForLender() {
    if (!this.searchTerm || this.searchTerm.length === 0) {
      return;
    }

    const params = {
      limit: 25,
      offset: 0,
      order_by: 'lenderNumber',
      order_desc: false,
      search_field: 'name_or_number',
      search_value: this.searchTerm,
    };

    try {
      const lenderSummary = await this.service.getAllLenders(params);
      this.otherLenders = lenderSummary.lenders;
    } catch (e) {
      console.log(e);
      this.otherLenders = [];
    }
    this.loading = false;

    this.currentDebounce = null;
  }

  async updateLender(): Promise<boolean> {
    if (!this.$v.lender) {
      return true;
    }

    const dirtyInvalidFields = this.nestedInvalidFields
      .filter((field) => at(this.$v.lender, field)[0].$dirty);

    const cleanInvalidFields = this.invalidFields
      .filter((field) => !at(this.$v.lender, field)[0].$dirty);

    if (this.$v.lender.$invalid) {
      // Do not allow update to continue if a dirtied field is invalid
      if (dirtyInvalidFields.length > 0) {
        console.error('Fields were invalid. Cancelling update.', dirtyInvalidFields);

        // Touch all invalid fields
        cleanInvalidFields.forEach((field) => this.$v.lender[field].$touch());
        this.showError({
          text: 'Could not save changes.  One or more form fields are invalid.  Please correct and try again.',
          timeout: 5000,
          closeable: true,
        });
        return false;
      }
    }

    try {
      const payload: JsonPatchPayload = vuelidateToPatch(this.$v.lender, [{
        key: 'address',
        converter: verifiedAddressConverter,
      }, {
        key: 'servicingType',
        converter: (value: any) => ({
          op: JsonPatchOperator.replace,
          path: '/loan_type',
          value: value.$model,
        }),
      }, {
        key: 'id',
        converter: (value: any) => ({
          op: JsonPatchOperator.replace,
          path: '/lender_number',
          value: value.$model,
        }),
      }, {
        key: 'serviceFee',
        converter: (value: any): JsonPatchEntry[] => currencyFieldConverter(value, 'serviceFee'),
      }], {
        contacts: 'lender_contact_id',
      });

      if (this.newLenders.length > 0) {
        this.newLenders.forEach((lender) => {
          delete lender.$pending;
          delete lender.$new;
          delete lender.index;
          delete lender.dialog;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/related_lenders/-',
            value: lender.lenderId,
          });
        });
      }

      if (this.newContacts.length > 0) {
        this.newContacts.forEach((contact) => {
          delete contact.$pending;
          delete contact.$new;
          delete contact.index;
          delete contact.dialog;
          payload.push({
            op: JsonPatchOperator.add,
            path: '/contacts/-',
            value: contact,
          });
        });
      }

      if (this.removedLenders.length > 0) {
        this.removedLenders.forEach((lender) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/related_lenders/${lender.related_lender_id}`,
          });
        });
      }

      if (this.removedContacts.length > 0) {
        this.removedContacts.forEach((contact) => {
          payload.push({
            op: JsonPatchOperator.remove,
            path: `/contacts/${contact.lender_contact_id}`,
          });
        });
      }

      if (payload.length === 0) {
        // TODO: Renotify that no changes were made?
        return true;
      }

      this.updating = true;
      await this.service.updateLender(this.$route.params.id, payload);

      this.$v.$reset();

      // Touch all untouched invalid fields
      cleanInvalidFields.forEach((field) => at(this.$v.lender, field)[0].$touch());

      // Reset all of the various subentity trackers
      this.newLenders = [];
      this.removedLenders = [];
      this.newContacts = [];
      this.removedContacts = [];

      this.$forceUpdate();

      this.getLender(true);
    } catch (e) {
      if (e.response.status === 412) {
        this.showError({
          text: 'This lender has been modified by another user. Please refresh to make changes.',
          actions: {
            text: 'Refresh',
            function: () => { document.location.reload() },
          },
        });
      } else {
        this.showError({
          text: 'Could not save changes.  There was an issue with the database.',
          timeout: 10000,
          closeable: true,
          actions: {
            text: 'Copy',
            function: this.copyError,
          },
        });
        this.patchError = e.response && e.response.data && e.response.data.detail;
      }
      this.updating = false;
      return false;
    }

    this.updating = false;
    return true;
  }

  private copyError() {
    const errorSummary = {
      error: this.patchError,
      form: {
        dirty: this.dirtyFields,
        invalid: this.nestedInvalidFields,
      },
    };

    this.$copyText(JSON.stringify(errorSummary));
  }

  async editToggle() {
    let shouldToggle = true;

    if (this.editMode) {
      // Clear all pending new subentities
      const flags: string[] = ['addingNewContact', 'addingNewRelatedLender'];
      const subentityArrays: string[] = ['contacts', 'relatedLenders'];
      flags.forEach((flag) => {
        this[flag as 'addingNewContact' | 'addingNewRelatedLender'] = false;
      });

      subentityArrays.forEach((array) => {
        (this.lender as any)[array] = ((this.lender as any)[array] as { $pending: boolean }[]).filter((entry) => !entry.$pending);
      });

      shouldToggle = await this.updateLender();
    }

    if (shouldToggle) {
      this.editMode = !this.editMode;
    }
  }

  getContactTypeColor(item: any) {
    const contactTypesList = JSON.parse(localStorage.contactTypes || '{}');

    if (contactTypesList[item.type]) {
      return contactTypesList[item.type];
    }

    return '#cccccc;';
  }

  resolveLink(link: string): string {
    if (!link) {
      return null;
    }

    return link.includes('://') ? link : `http://${link}`;
  }

  resolveAndOpenLink(link?: string, windowName?: string): boolean {
    if (!link) {
      return true;
    }

    const resolvedLink = this.resolveLink(link);
    window.open(resolvedLink, windowName, 'width=1024,height=768');

    return false;
  }

  verifyField(fieldToVerify: Verified, verified: boolean, key: string) {
    fieldToVerify.verified = verified;

    if (verified) {
      fieldToVerify.verifiedBy = this.user;
      fieldToVerify.verifiedOn = new Date();
    } else {
      fieldToVerify.verifiedBy = null;
      fieldToVerify.verifiedOn = null;
    }

    this.$v.lender[key].verified.$touch();
  }

  verifyEntity(verified: boolean) {
    this.lender.lenderVerified = verified;
    this.lender.lenderVerifiedBy = this.user;
    this.lender.lenderVerifiedOn = new Date();
    this.$v.lender.lenderVerified.$touch();
  }

  uploadFiles() {
    return Promise.all(
      this.files.map((file) => this.service.uploadFile(this.lender.lenderId, file)),
    ).then(() => {
      this.files = [];
      this.getLender(true, 'files');
    });
  }

  deleteFile(file: IFile) {
    axios.delete(file.url)
      .then(() => {
        this.lender.files = this.lender.files.filter((oldFile) => oldFile !== file);
      });
  }

  addNewRelatedLender() {
    this.addingNewRelatedLender = !this.addingNewRelatedLender;
    this.lender.relatedLenders.push({
      $pending: true,
      $new: true,
      index: this.lender.relatedLenders.length,
    });
  }

  saveNewRelatedLender() {
    if (this.selectedLender && this.determineName(this.selectedLender) === this.searchTerm) {
      // changing the chosen object so it displays well
      this.selectedLender.county = this.selectedLender.address.value.county;
      this.selectedLender.state = this.selectedLender.address.value.state;
      this.selectedLender.$pending = false;
      this.selectedLender.$new = true;

      this.lender.relatedLenders[this.lender.relatedLenders.length - 1] = cloneDeep(this.selectedLender);
      this.newLenders.push(cloneDeep(this.selectedLender));
      this.addingNewRelatedLender = !this.addingNewRelatedLender;
      this.lender.relatedLenders.forEach((c, i) => {
        c.index = i;
      });
    }
  }

  clearNewRelatedLender() {
    this.addingNewRelatedLender = !this.addingNewRelatedLender;
    this.lender.relatedLenders.pop();
  }

  removeRelatedLender(relatedLender: TrackableRelatedLender) {
    if (relatedLender.$new) {
      this.newLenders.splice(this.newLenders.findIndex((c) => c.index === relatedLender.index), 1);
    } else {
      this.removedLenders.push(relatedLender);
    }
    this.lender.relatedLenders.splice(relatedLender.index, 1);
    this.lender.relatedLenders.forEach((c, i) => {
      c.index = i;
    });
  }

  addNewContact() {
    this.addingNewContact = !this.addingNewContact;
    this.lender.contacts.push({
      $pending: true,
      $new: true,
      index: this.lender.contacts.length,
    });
  }

  saveNewContact() {
    this.addingNewContact = !this.addingNewContact;
    const contactEntry = this.lender.contacts[this.lender.contacts.length - 1];
    contactEntry.$pending = false;

    this.newContacts.push(contactEntry);
  }

  clearNewContact() {
    this.addingNewContact = !this.addingNewContact;
    this.lender.contacts.pop();
  }

  removeContact(contact: any) {
    console.log(contact.$new)
    if (contact.$new) {
      this.newContacts.splice(this.newContacts.find((c) => c.index === contact.index), 1);
    } else {
      this.removedContacts.push(contact);
    }
    this.lender.contacts.splice(contact.index, 1);
    this.lender.contacts.forEach((c, i) => {
      c.index = i;
    });
  }

  determineName(item: { name: string, id: string }) {
    return `${item.name} - ${item.id}`;
  }

  toggleActivate(flag: boolean) {
    if (!this.editMode) {
      return;
    }

    if (!this.confirmDeactivation) {
      this.confirmDeactivation = true;
      return;
    }

    this.lender.active = flag;
    this.$v.lender.active.$touch()
  }

  confirmDeletion() {
    this.confirmingDeletion = true;
  }

  dismissDeleteLenderDialog() {
    this.confirmingDeletion = false;
  }

  handleRollback() {
    this.showSuccess({
      text: 'Rollback successful. Please refresh the page to see changes.',
      actions: {
        text: 'Refresh',
        function: () => { document.location.reload() },
      },
    });
  }

  async uploadPicture() {
    await this.cropImage();
    await this.service.uploadPicture(this.lender.lenderId, this.croppedPicture);

    this.profileIdx += 1;
    this.pictureFile = null;
    this.showPictureUploadDialog = false;
  }

  setImage(file: File) {
    if (file.type.indexOf('image/') === -1) {
      alert('Please select an image file');
      return;
    }
    if (typeof FileReader === 'function') {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.rawPicture = (event.target as FileReader).result as string;
        (this.$refs.cropper as any).replace((event.target as FileReader).result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Sorry, FileReader API not supported');
    }

    this.$forceUpdate();
  }

  cropImage() {
    return new Promise<void>((resolve, reject) => {
      (this.$refs.cropper as any).getCroppedCanvas().toBlob((blob: Blob) => {
        this.croppedPicture = new File([blob], this.pictureFile.name);
        resolve();
      }, 'image/jpeg', '1.0');
    });
  }

  @Emit('deleted')
  async deleteLender() {
    const result = await this.service.deleteLender(this.$route.params.id);
    delete result.files;
    this.lender = { ...this.lender, ...result };
    this.confirmingDeletion = false;
    this.editMode = false;
    this.forceLeave = true;

    return this.lender;
  }
}
