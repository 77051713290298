



























































































































































































































































import {
  Component, Prop, Vue, Mixins,
} from 'vue-property-decorator';
import ContactTypesService from '@/services/contactTypes';

import Lenders from '@/views/lenders/Lenders.vue';
import Users from '@/views/users/Users.vue';
import Queries from '@/views/queries/Queries.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';

import { themes } from '../exports/themes';

@Component({
  name: 'admin-settings',
  components: {
    Queries,
    Users,
    Lenders,
  },
})
export default class AdminSettings extends Mixins(UserPermissions) {
  private contactTypesService: ContactTypesService = new ContactTypesService();
  private isLoading: boolean = false;
  private errorMessage: string = '';

  private errorDialog: boolean = false;
  private newAgencyContactType: boolean = false;
  private newLenderContactType: boolean = false;
  private contactTypeName: string = '';
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };

  private themes = themes;

  private contactTypes: any[] = [];
  private currentType: string = '';
  private currentColor: string = '';
  private themeChoice: number = parseInt(localStorage.themeCount, 10) || 0;
  private fontSizeChoice: number = this.$parent.$data.currentFontSize || 1;

  // Computed
  get agencyContactTypesList() {
    return this.contactTypes.filter((type) => type.entity === 'agencies');
  }

  get lenderContactTypesList() {
    return this.contactTypes.filter((type) => type.entity === 'lenders');
  }

  async created() {
    await this.getLatestContactTypes();
  }

  async getLatestContactTypes() {
    const contactTypeResponse = await this.contactTypesService.getContactTypes();
    this.contactTypes = contactTypeResponse.types;
    this.contactTypes.forEach((c, i) => {
      c.index = i;
    });
  }

  async createContactType(name: any, entity: any) {
    if (!(this.$refs.contactTypeForm as any).validate()) {
      return;
    }

    this.isLoading = true;

    try {
      await this.contactTypesService.addContactType({ name, entity });
      this.contactTypeName = '';
      this.getLatestContactTypes();
    } catch (e) {
      this.errorMessage = e.message;
      this.errorDialog = true;
    } finally {
      this.isLoading = false;
      this.newAgencyContactType = false;
      this.newLenderContactType = false;
    }
  }

  themeChanged() {
    this.$root.$children[0].$data.theme = this.themes[this.themeChoice];
    document.body.style.background = this.$root.$children[0].$data.theme.backgroundColor.toString();

    localStorage.theme = JSON.stringify(this.$root.$children[0].$data.theme);
    localStorage.themeCount = this.themeChoice;
  }

  fontSizeChanged() {
    this.$root.$children[0].$data.currentFontSize = this.fontSizeChoice;
    this.$parent.$data.currentFontSize = this.fontSizeChoice;
    localStorage.currentFontSize = this.fontSizeChoice;
  }

  async removeContactType(type: any) {
    await this.contactTypesService.deleteContactType(type.contactTypeId);
    this.getLatestContactTypes();
  }
}
