











































































































import { get } from 'lodash';
import {
  Component, Vue,
} from 'vue-property-decorator';
import { DataTableCompareFunction, DataTableHeader } from 'vuetify';

import User from '@/entities/User';
import UsersService from '@/services/users';
import LenderService from '@/services/lenders';
import { JsonPatchOperator } from '@/helpers/vuelidateToPatch';

@Component({
  name: 'users-list',
})
export default class UsersList extends Vue {
  private usersService: UsersService = new UsersService();
  private lenderService: LenderService = new LenderService();

  private isLoading = true;
  private editMode = false;

  private userToDelete: User = null;
  private deleteConfirm = false;

  private searchText = '';

  private lenders: any[] = [];

  private sortDesc: boolean = false;

  private nullsLast = (nameA: string, nameB: string, isDesc: boolean) => {
    if (!nameA && !nameB) {
      return 0;
    }

    if (!nameA) {
      return 1;
    }

    if (!nameB) {
      return -1;
    }

    return nameA.localeCompare(nameB) * (isDesc ? -1 : 1);
  }

  private customSort = (items: any[], sortBy: string[], isDesc: boolean[], locale: string, customSorters?: Record<string, DataTableCompareFunction>) => {
    if (sortBy.length > 1) {
      console.warn('Custom sorter does not support multiple.');
      return items;
    }

    let itemKey = sortBy[0];

    if (customSorters && customSorters[itemKey]) {
      items.sort((a, b) => customSorters[itemKey](a[itemKey], b[itemKey]) * (isDesc[0] ? -1 : 1));
    } else {
      if (itemKey === 'lenders') {
        itemKey = 'lenders.0.name';
      }

      items.sort((a, b) => this.nullsLast(get(a, itemKey), get(b, itemKey), isDesc[0] || false));
    }

    return items;
  }

  private headers: DataTableHeader[] = [
    {
      text: 'First Name',
      value: 'givenName',
      sortable: true,
    },
    {
      text: 'Last Name',
      value: 'familyName',
      sortable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
    },
    { text: 'Roles', value: 'roles', sortable: false },
    { text: 'Status', value: 'active', sortable: true },
    {
      text: 'Lender',
      value: 'lenders',
      sortable: true,
    },
    {
      text: 'Last Login',
      value: 'lastLogin',
      sortable: true,
      sort: (loginA: Date | undefined, loginB: Date | undefined) => {
        if (!loginA && !loginB) {
          return 0;
        }

        if (!loginA) {
          return 1;
        }

        if (!loginB) {
          return -1;
        }

        const aInMillis = loginA ? loginA.getTime() : 0;
        const bInMillis = loginB ? loginB.getTime() : 0;
        return aInMillis - bInMillis;
      },
    },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  private users: User[] = [];

  private roles: String[] = [
    'Capital User',
    'Lender User',
    'Lender Admin',
    'Capital Trainee',
    'Capital Admin',
  ]

  // Hooks
  async created() {
    this.getUsers();

    await this.getAllLenders();
  }

  // Methods
  async getUsers() {
    this.isLoading = true;
    this.usersService.getUsers()
      .then((users) => {
        this.users = users;
        this.isLoading = false;
      })
  }

  logoutUser(id: string) {
    this.usersService.logoutUser(id)
      .then(() => console.log('Logged out this user!'));
  }

  toggleUserStatus(user: User) {
    this.usersService.updateUser(user.id, { active: !user.active })
      .then(() => {
        user.active = !user.active;
      });
  }

  async deleteUser(user: User) {
    if (!this.deleteConfirm) {
      this.deleteConfirm = true;
      this.userToDelete = user;
      return;
    }

    await this.usersService.deleteUser(user.id);

    this.deleteConfirm = false;
    this.getUsers();
  }

  edit() {
    console.log('edit')
    this.editMode = true;
  }

  async save() {
    console.log('save')
    this.isLoading = true;
    this.editMode = false;
    const updateCalls: any[] = [];
    this.users.filter((u) => (u.lenderId && u.lenderId !== '')).forEach((u) => {
      console.log(u.lenderId)
      const patchPayload = [];
      if (u.lenderId && u.lenderId !== '') {
        patchPayload.push({ op: JsonPatchOperator.replace, path: '/lender_id', value: u.lenderId })
      }

      updateCalls.push(this.usersService.patchUser(u.id, patchPayload))
    })
    const results = await Promise.all(updateCalls);
    this.getUsers()
  }

  cancel() {
    console.log('cancel')
    this.editMode = false;
    // this.users.forEach((u) => {
    //   u.selectedLender = null;
    // });
  }

  async getAllLenders() {
    const params = {
      order_by: 'lenderNumber',
      order_by_desc: false,
    };

    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.lenders.forEach((lender) => {
        lender.displayString = `${lender.name} - ${lender.id}`;
      });
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
  }

  remove(item: any, data: any) {
    item.lenderId = null;
  }

  add(item: any, data: any) {
    // item.selectedLender = null;
  }

  removeRole(item: any, data: any) {
    // item.lenderId = null;
  }

  addRole(item: any, data: any) {
    item.rolesChanged = true
    // item.selectedLender = null;
  }
}
