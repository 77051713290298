




















import {
  Component, Prop, Emit, Vue, Mixins, Model, Watch,
} from 'vue-property-decorator';

import { latLngBounds } from 'leaflet';

import _ from 'lodash';

import statesJson from '@/data/usa-states.json';
import statesData from '@/data/example-data.json';

@Component({
  name: 'usa-chloropleth',
  computed: {},
})
export default class UsaChloropleth extends Vue {
  @Prop({
    type: Array,
    default: () => statesData,
  }) private readonly data!: [any];

  private url: string = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';

  private attribution: string = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

  private statesJson: any = statesJson;

  private chloroColorScale: string[] = ['e7d090', 'e9ae7b', 'de7062'];

  private usaBounds: any = latLngBounds([
    [25.118333, -124.7844070],
    [49.384472, -66.949778],
  ]);

  created() {}

  mounted() {
    // Disable user controls
    (this.$el.querySelector('.leaflet-control-zoom') as any).style.visibility = 'hidden';
    (this.$refs.map as any).mapObject.dragging.disable();
  }
}
