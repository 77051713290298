




























































































import {
  Component, Prop, Emit, Mixins,
} from 'vue-property-decorator';
import { debounce } from 'lodash';
import { DataTableHeader } from 'vuetify';

import Lender from '@/entities/Lender';

import LenderService from '@/services/lenders';

import UserPermissions from '@/mixins/UserPermissions.vue';

@Component({
  name: 'lenders-list',
})
export default class LendersList extends Mixins(UserPermissions) {
  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: any;

  @Prop({
    type: String,
  }) private readonly view!: string;

  private service: LenderService = new LenderService();
  private lenderSearch: string = '';
  private totalLenders = 0;
  private isLoading = true;
  private currentOptions: any = {};
  private currentSearch = '';
  private currentDebounce: Function = null;
  private showServTypeDropdown = false;
  private confirmingDeletion = false;
  private lenderToDelete:any = {};
  private isCurrentlyDeleting = false;

  private lenders: Lender[] = [];

  private columns: DataTableHeader[] = [
    { text: 'Lender Number', value: 'id' },
    { text: 'Lender Name', value: 'name' },
    { text: 'State', value: 'address.value.state' },
    { text: 'Email', value: 'email' },
    { text: 'Active', value: 'active' },
  ];

  private searchCriteria: DataTableHeader[] = [
    {
      text: 'All',
      value: 'all',
    },
    {
      text: 'Lender Number',
      value: 'lendernumber',
    },
    {
      text: 'Lender Name',
      value: 'name',
    },
    {
      text: 'State',
      value: 'state',
    },
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Servicing Type',
      value: 'servicingtype',
    },
  ];

  private currentSearchBy: DataTableHeader = {
    text: 'All',
    value: 'all',
  };

  private servTypes: DataTableHeader[] = [
    {
      text: 'Escrow',
      value: 'ESC',
    },
    {
      text: 'Non-Escrow',
      value: 'NON',
    },
    {
      text: 'Both',
      value: 'BOTH',
    },
  ];

  private searchServType: DataTableHeader = {
    text: 'Both',
    value: 'BOTH',
  };

  private showInactiveLenders = false;

  async created() {
    if (this.view === 'admin-settings' && this.isAdmin) {
      this.columns.push({ text: 'Actions', value: 'actions' });
    }
  }

  async updateOptions(options: any) {
    this.isLoading = true;
    this.currentOptions = options;
    this.getLenders();
  }

  async searchFieldUpdated(search: string) {
    const minSearch = this.currentSearchBy.value === 'state' ? 1 : 2;
    this.currentSearch = search;

    if (this.currentSearch.length >= minSearch || this.currentSearch.length === 0) {
      this.currentOptions.page = 1;
      this.getLenders();
    }
  }

  async updateSearchBy() {
    if (this.currentSearchBy.value === 'servicingtype') {
      this.showServTypeDropdown = true;
      this.searchServType = {
        text: 'Both',
        value: 'BOTH',
      };
      this.lenderSearch = '';
      this.currentSearch = '';
    } else {
      this.showServTypeDropdown = false;
    }
    this.currentOptions.page = 1;
    this.searchFieldUpdated(this.lenderSearch);
  }

  async getLenders() {
    this.isLoading = true;

    if (!this.currentDebounce) {
      this.currentDebounce = debounce(this.searchForLender, 500);
    }

    this.currentDebounce();
  }

  async searchForLender() {
    const params: any = {
      limit: this.currentOptions.itemsPerPage,
      offset: (this.currentOptions.page - 1) * this.currentOptions.itemsPerPage,
      order_by: 'lenderNumber',
      order_by_desc: false,
      show_inactive_lenders: this.showInactiveLenders,
    };

    if (this.currentSearch && this.currentSearch.length > 0) {
      params.search_field = this.currentSearchBy.value;
      params.search_value = this.currentSearch;
    }

    if (this.currentOptions.sortBy.length > 0) {
      [params.order_by] = this.currentOptions.sortBy;
      [params.order_by_desc] = this.currentOptions.sortDesc;
    }

    if (this.currentSearchBy.value === 'servicingtype') {
      params.search_field = this.currentSearchBy.value;
      params.search_value = this.searchServType.value;
    }

    try {
      const lenderSummary = await this.service.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
      this.totalLenders = lenderSummary.count;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
    this.isLoading = false;
  }

  confirmDeletion(lender: any) {
    this.lenderToDelete = lender;
    this.confirmingDeletion = true;
  }

  dismissDeleteLenderDialog() {
    this.confirmingDeletion = false;
  }

  @Emit('deleted')
  async deleteLender() {
    this.isCurrentlyDeleting = true;
    const result = await this.service.deleteLender(this.lenderToDelete.lenderId);
    this.isCurrentlyDeleting = false;
    delete result.files;
    this.lenderToDelete = { ...this.lenderToDelete, ...result };
    this.confirmingDeletion = false;

    this.getLenders();
    return this.lenderToDelete;
  }

  @Emit('select')
  handleRowClick(lender: Lender) {
    return lender;
  }
}
