





















































































































import _, { cloneDeep } from 'lodash';
import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import QueriesService from '@/services/queries';

import Query from '@/entities/queries/Query';

import QueryDefinition from '@/views/reports/builder/QueryDefinition.vue';

import { configMapBuilder } from '@/router';

import * as config from '@/reportConfigs.json';

@Component({
  name: 'query-list',
  components: {
    QueryDefinition,
  },
})
export default class QueriesList extends Vue {
  @Prop({
    type: Array,
    default: (): Query[] => [],
  }) private readonly queries!: Query[];

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly isLoading!: boolean;

  private queriesService: QueriesService = new QueriesService();
  private querySearch: string = '';
  private clonedQuery: Query = null;

  private selections: Map<string, any[]> = null;

  private headers: DataTableHeader[] = [
    {
      text: 'Name',
      value: 'name',
      sortable: true,
      width: '10%',
    },
    {
      text: 'Description',
      value: 'description',
      sortable: false,
      width: '20%',
    },
    {
      text: 'Creator',
      value: 'creator',
      sortable: true,
      width: '20%',
    },
    {
      text: 'Tags',
      value: 'tags',
      sortable: false,
      width: '20%',
    },
    {
      text: 'Viewable',
      value: 'viewable',
      sortable: true,
      width: '15%',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: '15%',
    },
  ];

  // Hooks
  created() {
    this.selections = configMapBuilder(
      [config.lenderDataReport, config.agencyDataReport, config.loanDataReport],
    );
  }

  // Methods
  startClone(query: Query) {
    this.clonedQuery = cloneDeep(query);
  }

  @Emit('delete')
  deleteQuery(query: Query) {
    return query;
  }

  openQuery(query: Query) {
    this.$router.push({ path: `/queries/${query.id}` });
  }

  cloneQuery() {
    this.$emit('clone', this.clonedQuery);
    this.clonedQuery = null;
  }

  @Emit('update')
  updateQuery(key: string, value: any, queryToUpdate: Query) {
    const index = this.queries.findIndex((query) => query.id === queryToUpdate.id);

    if (index === -1) {
      throw new Error('Could not find query being updated.');
    }

    return {
      index,
      key,
      value,
    };
  }
}
