




















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { snakeCase } from 'change-case';
import _ from 'lodash';

import QueriesList from '@/views/queries/QueriesList.vue';
import UserService, { QueryPost } from '@/services/users';
import RoleService from '@/services/roles';

import Query from '@/entities/queries/Query';
import { Role } from '@/entities/User';

import { JsonPatchPayload, JsonPatchOperator } from '@/helpers/vuelidateToPatch';

@Component({
  name: 'queries',
  components: {
    QueriesList,
  },
})
export default class Queries extends Vue {
  private queries: Query[] = [];
  private roleOptions: Role[] = [];

  private isLoading = false;

  private userService: UserService = new UserService();
  private roleService: RoleService = new RoleService();

  // New query controls
  private addQueryMode: boolean = false;
  private addQueryError: boolean = false;
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };
  private email: string = '';
  private roleId: string = '';

  created() {
    this.getQueries();
  }

  getQueries() {
    this.isLoading = true;
    this.userService.getAllUserQueries()
      .then((queries) => {
        this.queries = queries;
        this.isLoading = false;
      })
  }

  deleteQuery(query: Query) {
    this.userService.deleteUserQuery(query.id)
      .then(() => {
        this.queries = this.queries.filter((candidateQuery) => candidateQuery.id !== query.id);
      });
  }

  cloneQuery(query: Query) {
    const clone: QueryPost = {
      name: query.name,
      description: query.description,
      tags: query.tags,
      query: query.query,
      creator_id: query.creator.id,
    };

    this.userService.addUserQuery(clone)
      .then((newQuery) => {
        this.queries.push(newQuery);
      });
  }

  updateQuery(key: string, value: any, index: number) {
    const queryToUpdate = this.queries[index];
    const payload: JsonPatchPayload = [{
      op: JsonPatchOperator.replace,
      path: `/${snakeCase(key)}`,
      value,
    }];

    this.userService.updateUserQuery(queryToUpdate.id, payload)
      .then((query) => {
        this.queries[index] = query;
      });
  }
}
