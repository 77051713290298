













































































import {
  Component, Vue, Watch,
} from 'vue-property-decorator';

import { debounce } from 'lodash';

import UsersList from '@/views/users/UsersList.vue';
import UserService from '@/services/users';
import RoleService from '@/services/roles';
import Lender from '@/entities/Lender';
import LenderService from '@/services/lenders';
import User, { Role } from '@/entities/User';

@Component({
  name: 'users',
  components: {
    UsersList,
  },
})
export default class Users extends Vue {
  private users: User[] = [];
  private roleOptions: Role[] = [];

  private isLoading = false;

  private userService: UserService = new UserService();
  private roleService: RoleService = new RoleService();
  private lenderService: LenderService = new LenderService();

  // New user controls
  private addUserMode: boolean = false;
  private addUserError: boolean = false;
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };
  private email: string = '';
  private roleId: string = '';

  private selectedLender: Lender = null;
  private currentDebounce: Function = null;
  private searchTerm: string = '';
  private lenders: Lender[] = [];
  private showLender: boolean = false;

  created() {
    this.roleService.getRoles()
      .then((roles) => {
        this.roleOptions = roles;
      })
  }

  async createUser(email: string, roleId: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.isLoading = true;

    const payload: any = {
      email,
      roles: [roleId],
    };

    if (this.isLenderRelatedRole(roleId)) {
      payload.lender_id = this.selectedLender.lenderId
    }

    try {
      this.addUserError = false;
      await this.userService.createUser(payload);
      this.email = '';
      this.roleId = '';
      this.selectedLender = null;
      this.addUserMode = false;
    } catch (e) {
      this.addUserError = true;
    } finally {
      this.isLoading = false;
    }
  }

  async getLenders(searchTerm: string) {
    this.searchTerm = searchTerm;
    if (!this.currentDebounce) {
      this.currentDebounce = debounce(this.searchForLender, 1000);
    }

    this.currentDebounce();
  }

  async searchForLender() {
    if (!this.searchTerm || this.searchTerm.length === 0) {
      return;
    }

    const params = {
      limit: 25,
      offset: 0,
      order_by: 'lenderNumber',
      order_desc: false,
      search_field: 'name_or_number',
      search_value: this.searchTerm,
    };

    try {
      const lenderSummary = await this.lenderService.getAllLenders(params);
      this.lenders = lenderSummary.lenders;
    } catch (e) {
      console.log(e);
      this.lenders = [];
    }
    this.isLoading = false;

    this.currentDebounce = null;
  }

  determineName(item: Lender) {
    return `${item.name} - ${item.id}`;
  }

  @Watch('roleId')
  toggleShowLender(val: string, oldVal: string) {
    const selectedRole = this.roleOptions.find((role) => role.id === val);
    if (this.isLenderRelatedRole(val)) {
      this.showLender = true;
    } else {
      this.showLender = false;
    }
  }

  isLenderRelatedRole(roleId: string): boolean {
    const selectedRole = this.roleOptions.find((role) => role.id === roleId);
    return !!(selectedRole && ['Lender User', 'Lender Admin'].includes(selectedRole.name));
  }
}
