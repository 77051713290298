















































































import {
  Component, Prop, Mixins,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';

import _ from 'lodash';

import Lender from '@/entities/Lender';

import LendersList from '@/views/lenders/LendersList.vue';
import LendersDetail from '@/views/lenders/LendersDetail.vue';
import LenderService from '@/services/lenders';

import UserPermissions from '@/mixins/UserPermissions.vue';
import FontCalculation from '@/mixins/FontCalculation.vue';

@Component({
  name: 'lenders',
  components: {
    LendersList,
    LendersDetail,
  },
})
export default class Lenders extends Mixins(UserPermissions, FontCalculation) {
  @Prop({
    type: String,
  }) private readonly view!: string;

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly showTitle!: boolean;

  @Action('showSuccess') showSuccess!: (val: any) => {};

  private lenders: Lender[] = [];
  private currentView = 0;
  private lenderService: LenderService = new LenderService();
  private isLoading = false;
  private errorMessage = 'Unable to create new lender. Please try again later.';
  private lender: Lender = null;

  private errorDialog = false;
  private newLender = false;
  private lenderNumber = '';
  private lenderName = '';
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };

  // Computed
  get selectedLender(): boolean {
    return Boolean(this.$route.params.id);
  }

  get hasTitle(): boolean {
    return this.view !== 'panel-wall' && this.showTitle;
  }

  async createLender(lenderNumber: string, lenderName: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.isLoading = true;
    const payload = {
      lender_number: lenderNumber,
      name: lenderName,
    };

    try {
      const lenderResponse = await this.lenderService.addLender(payload);
      this.newLender = false;
      if (lenderResponse.lenderId !== undefined) {
        this.lenderNumber = '';
        this.lenderName = '';
        this.handleSelectedLender(lenderResponse, true);
      }
    } catch (e) {
      if (e.response && e.response.status >= 400) {
        this.errorMessage = e.response.data.message;
        this.errorDialog = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  handleSelectedLender(lender: Lender, edit = false) {
    if (this.view === 'admin-settings') {
      // do nothing
    } else if (this.view === 'panel-wall') {
      this.lender = lender;
    } else {
      const queryParams = edit ? { editMode: edit.toString() } : {};
      this.$router.push({ path: `/lenders/${lender.lenderId}`, query: queryParams });
    }
  }

  clearSelection(deletedLender?: Lender) {
    if (deletedLender) {
      this.showSuccess(`Successfully deleted lender <b>${deletedLender.name}</b>`);
    }

    if (this.view === 'panel-wall') {
      this.lender = null;
    } else {
      this.$router.push({ path: '/lenders' });
    }
  }
}
